import { getActiveRequestByOrg } from '../requestsApi/fbReadRequestsApi'
import { matchCheckinCase, checkinPass } from './fbCheckinCase'
import { matchCheckoutCases, checkoutPass } from './fbCheckoutCase'
import { fbReadAppointment } from './fbFindPass'
import { fbResolveForNoAppointment } from './fbNoAppointment'
import { resolveForRejection } from './fbRejectionCase'
import { fbResolveForFailure } from './fbResolveForFailure'

/**
 * @param {String} code The QR Code
 * @param {Object} context OU
 * @param {Object} vstore Vuex store
 * @returns Object
 */
export async function sendCheck(code, context, vstore) {
  let response = await Promise.resolve(null)
  const { exists, passRef, passData } = await fbReadAppointment(code, context)
  if (!exists) {
    response = await fbResolveForNoAppointment()
  } else {
    const request = await getActiveRequestByOrg(context, passData.reqId)
    try {
      if (matchCheckoutCases(passData)) {
        const postCheckoutStage = await checkoutPass(passRef, passData, request)
        response = await postCheckoutStage(request, vstore)
      } else if (matchCheckinCase(passData)) {
        const postCheckinStage = await checkinPass(passRef, passData)
        response = await postCheckinStage(request, vstore)
      } else {
        response = await resolveForRejection(passData)
      }
      response = await Promise.resolve({
        ...response,
        name: request.party.name
      })
    } catch (error) {
      console.log('fbCheckApi/catch', error)
      response = await fbResolveForFailure(response)
      console.log('Can not update the check action', error)
    }
  }
  return response
}
