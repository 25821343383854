import { ExceptionEvaluation, UnknowQuality } from '@/cfg'

export async function fbResolveForFailure(response) {
  const quality = { evaluation: ExceptionEvaluation, quality: UnknowQuality }
  const status = { type: 'failure', quality }
  return Promise.resolve({
    ...response,
    ...{
      successful: false,
      welcome: 'ALGO SALIÓ MAL',
      name: 'Problemas con la red / Error de Aplicación',
      comment: 'Por Favor, Intentar Nuevamente',
      status
    }
  })
}
