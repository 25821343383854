import { currentPeriodAsId, previousPeriodAsId } from '@/micro/time/dateId'
import { foundAppointment, getAttendantPassPath } from './fbCheckControl'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

export async function fbReadAppointment(code, context) {
  const db = firebase.firestore()
  let pathId = currentPeriodAsId()
  let path = getAttendantPassPath(context, pathId)

  // Try in the current day
  let passQuery = await passDataByPeriod(path, code, db)
  // Search in a day before
  if (!foundAppointment(passQuery)) {
    const pathId = previousPeriodAsId()
    path = getAttendantPassPath(context, pathId)
    passQuery = await passDataByPeriod(path, code, db)
  }

  let read = { exists: false, passData: {}, passRef: {} }
  if (foundAppointment(passQuery)) {
    const passDoc = passQuery.docs[0]
    const passData = passDoc.data()
    const passRef = db.doc(`${path}/${passDoc.id}`)
    read = { exists: true, passData, passRef }
  }
  return read
}

async function passDataByPeriod(path, code, db) {
  let passRef = db.collection(path)
  let passQuery = passRef.where('code', '==', Number(code)).limit(1)
  const res = await passQuery.get()
  return res
}
