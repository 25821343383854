import { isWithinTimeToCheckin } from '@/components/requests/pass/checkinTimeControl'
import {
  isInUnexpectedTimeToCheckoutFromPeriod,
  isWithinTimeToCheckout
} from '@/components/requests/pass/checkoutTimeControl'
import {
  hasCheckedIn,
  periodIn,
  stagesInRequest
} from '@/components/requests/reqsControl'
import {
  hasAuth3Stage,
  isAuth3Approved
} from '@/components/requests/workflow/stagesControl'

/**
 * @param {string} did The date id
 */
export function getAttendantPassPath({ ou }, did) {
  return `pass/${ou}/${did}`
}

export function foundAppointment(passQuery) {
  return !passQuery.empty
}

export function isReadyToCheckin(passData) {
  return !hasCheckedIn(passData) && isWithinTimeToCheckin(periodIn(passData))
}

export function isReadyToCheckoutWithoutAuth3(passData, request) {
  return isReadyToCheckout(passData) && !hasAuth3Stage(stagesInRequest(request))
}

export function isReadyToCheckoutAfterAuth3Approval(passData, request) {
  const stages = stagesInRequest(request)
  return (
    isReadyToCheckout(passData) &&
    hasAuth3Stage(stages) &&
    isAuth3Approved(stages)
  )
}

export function isReadyToCheckoutAfterAuth3Rejection(passData, request) {
  const stages = stagesInRequest(request)
  return (
    isReadyToCheckout(passData) &&
    hasAuth3Stage(stages) &&
    !isAuth3Approved(stages)
  )
}

export function isReadyToCheckout(passData) {
  return hasCheckedIn(passData) && isWithinTimeToCheckout(periodIn(passData))
}

export function isExceptionalCheckout(passData) {
  return (
    hasCheckedIn(passData) &&
    isInUnexpectedTimeToCheckoutFromPeriod(periodIn(passData))
  )
}

// If it were a successful checkin it would have the checkin timestamp
export function wasCheckinIntent(passData) {
  return !hasCheckedIn(passData)
}
