import { isEarlierPassException, isExpectedPass } from '@/cfg'
import { periodIn } from '@/components/requests/reqsControl'
import { checkoutQualityByTime } from './checkPolicies'
import {
  isReadyToCheckout,
  isReadyToCheckoutAfterAuth3Approval,
  isReadyToCheckoutAfterAuth3Rejection,
  isReadyToCheckoutWithoutAuth3
} from './fbCheckControl'
import { fbPostCheck } from './fbPostCheck'

export function matchCheckoutCases(passData) {
  return isReadyToCheckout(passData)
}

export async function checkoutPass(passRef, passData, request) {
  // CHECKOUT
  // if (multiPass(passData)) {
  //   await passRef.update() to remove the checkin
  // } else {
  //   await passRef.delete()
  // }
  const period = periodIn(passData)
  const quality = checkoutQualityByTime(period)
  const isExpected = isExpectedPass(quality)
  const isEarlier = isEarlierPassException(quality)
  let successful = false
  let welcome = ''
  let comment = ''
  if (isReadyToCheckoutAfterAuth3Approval(passData, request)) {
    successful = true
    comment = isExpected
      ? 'Salida Esperada y con Pase Médico'
      : isEarlier
      ? 'Salida anticipada y con Pase Médico'
      : 'Salida tardía y con Pase Médico'
  } else if (isReadyToCheckoutAfterAuth3Rejection(passData, request)) {
    successful = true
    welcome = 'NO PASÓ LA REVISIÓN MÉDICA'
    comment = isExpected
      ? 'Te deseamos una pronta recuperación'
      : isEarlier
      ? 'Te deseamos una pronta recuperación'
      : 'Informar al Jefe de Seguridad: No Pasó la Revisión Médica y HA SALIDO MUY TARDE'
  } else if (isReadyToCheckoutWithoutAuth3(passData, request)) {
    successful = true
    welcome = 'SIN REVISIÓN MÉDICA'
    comment = isExpected
      ? 'Informar al Jefe de Seguridad. No se Presentó a Revisión Médica'
      : isEarlier
      ? 'Informar al Jefe de Seguridad. No se Presentó a Revisión Médica'
      : 'Informar al Jefe de Seguridad. No se Presentó a Revisión Médica y HA SALIDO MUY TARDE'
  }
  try {
    await passRef.delete()
  } catch (error) {
    console.log('Error catched, why?', passRef, response, error)
  }

  const status = { type: 'checkout', quality }
  // const fullname = partyInProfile(profileInRequest(request)).fullname
  // welcome: `${fullname}, ${welcome}`,
  const response = {
    action: 'approve',
    successful,
    welcome,
    name: `¡Hasta pronto!`,
    comment,
    status
  }
  return async (request, vstore) => await fbPostCheck(request, response, vstore)
}
