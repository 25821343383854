import { periodIn } from '@/components/requests/reqsControl'
import { isReadyToCheckin } from './fbCheckControl'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { fbPostCheck } from './fbPostCheck'
import { checkinQualityByTime } from './checkPolicies'
import { isExpectedPass, isEarlierPassException } from '@/cfg'

export function matchCheckinCase(passData) {
  return isReadyToCheckin(passData)
}

export async function checkinPass(passRef, passData) {
  const period = periodIn(passData)
  const quality = checkinQualityByTime(period)
  const status = { type: 'checkin', quality }
  await passRef.update({
    checkin: firebase.firestore.FieldValue.serverTimestamp()
  })
  const response = {
    action: 'approve',
    successful: true,
    name: `Entrada`,
    welcome: `¡Hola!`,
    comment: isExpectedPass(quality)
      ? '¡A tiempo!'
      : isEarlierPassException(quality)
      ? 'Ingreso anticipado'
      : 'Ingreso tardío',
    status
  }
  return async (request, vstore) => fbPostCheck(request, response, vstore)
}
