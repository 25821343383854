import { qualityByTime, ExceptionEvaluation } from '@/cfg'
import {
  isOnEarlierTimeToCheckin,
  isOnLateTimeToCheckin
} from '@/components/requests/pass/checkinTimeControl'
import {
  isCheckoutAheadSchedule,
  isOnLateTimeToCheckout
} from '@/components/requests/pass/checkoutTimeControl'

export const checkoutQualityByTime = period => {
  const isEarlier = isCheckoutAheadSchedule(period.start, period.end)
  const isLate = isOnLateTimeToCheckout(period)
  return qualityByTime(isEarlier, isLate)
}

export const checkinQualityByTime = period => {
  const isEarlier = isOnEarlierTimeToCheckin(period)
  const isLate = isOnLateTimeToCheckin(period)
  return qualityByTime(isEarlier, isLate)
}

export const qualityOfValidation = validation =>
  validation.status
    ? validation.status.quality
    : { evaluation: ExceptionEvaluation }

export const typeOfValidation = validation =>
  validation.status ? validation.status.type : 'unknown'
