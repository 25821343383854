import { ExceptionEvaluation, NoAppointment } from '@/cfg'

export async function fbResolveForNoAppointment() {
  const quality = { evaluation: ExceptionEvaluation, quality: NoAppointment }
  const status = { type: 'checkin', quality }
  const response = {
    action: 'reject',
    successful: false,
    welcome: `SIN PASE DE ACCESO`,
    name: '',
    comment: 'Sin Solicitud, Ni Autorización, o Vencido',
    status
  }
  return Promise.resolve(response)
}
