import {
  EarlierQuality,
  ExceptionEvaluation,
  LateQuality,
  warningEarlierDurationToCheckin
} from '@/cfg'
import { isBeforeWarningTimeToCheckin } from '@/components/requests/pass/checkinTimeControl'
import { isAfterCheckout } from '@/components/requests/pass/checkoutTimeControl'
import { periodIn } from '@/components/requests/reqsControl'
import { hoursFromDate } from '@/micro/time/dateFormat'
import { wasCheckinIntent } from './fbCheckControl'

export async function resolveForRejection(passData) {
  let action = 'reject'
  let successful = false
  const welcome = 'SITUACIÓN INESPERADA'
  const period = periodIn(passData)
  // OUT OF TIME
  if (wasCheckinIntent(passData)) {
    const isBeforeWarning = isBeforeWarningTimeToCheckin(periodIn(passData))
    const comment = isBeforeWarning
      ? `Sólo Puedes Ingresar ${warningEarlierDurationToCheckin()} MINUTOS ANTES de lo progamado.`
      : isAfterCheckout(period)
      ? `No Puedes Entrar Después de que ha Pasado tu Hora de Salida`
      : 'Por favor, intenta en un momento más'
    const quality = {
      evaluation: ExceptionEvaluation,
      quality: isBeforeWarning ? EarlierQuality : LateQuality
    }
    const status = { type: 'checkin', quality }
    const response = {
      action,
      welcome,
      comment,
      successful,
      name: 'Ingreso',
      status
    }
    return Promise.resolve(response)
  } else {
    // UNEXPECTED EXIT
    const quality = {
      evaluation: ExceptionEvaluation,
      quality: EarlierQuality
    }
    const status = { type: 'checkout', quality }
    const comment = `Por favor espera tu salida hasta la hora en que programaste tu ingreso: ${hoursFromDate(
      period.start
    )} o bien, pasa a REVISIÓN MÉDICA.`
    const response = {
      action,
      name: 'Salida sin revisión médica',
      welcome: 'SIN REVISIÓN MÉDICA',
      comment,
      successful,
      status
    }
    return Promise.resolve(response)
  }
}
