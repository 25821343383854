// import { utcToZonedTime } from 'date-fns-tz/fp'
import { format, formatWithOptions } from 'date-fns/fp'
import { es } from 'date-fns/locale'

export function shortFormattedDate(dateStr) {
  const shortFormat = formatWithOptions({ locale: es }, 'eee, d MMM hh:mm')
  return shortFormat(new Date(dateStr))
}

export function groupHeaderFormattedDate(dateStr) {
  const groupHeaderFormat = formatWithOptions({ locale: es }, 'eee, d MMM H:mm')
  return groupHeaderFormat(new Date(dateStr))
}

export function justFormattedDate(dateStr) {
  const shortFormat = formatWithOptions({ locale: es }, 'd MMM yyyy')
  return shortFormat(new Date(dateStr))
}

export function humanFormattedDate(date) {
  const shortFormat = formatWithOptions({ locale: es }, "d 'de' MMM 'de' yyyy")
  return shortFormat(date)
}

export function longFormattedDate(dateStr) {
  const longFormat = formatWithOptions({ locale: es }, 'eee, d MMM yyyy')
  return longFormat(new Date(dateStr))
}

export function formattedDateTime(dateStr) {
  const longFormat = formatWithOptions(
    { locale: es },
    'eee, d.MMM.yyyy hh:mm aa'
  )
  return longFormat(new Date(dateStr))
}

export function hoursFromDate(dateStr) {
  const longFormat = formatWithOptions({ locale: es }, 'H:mm')
  return longFormat(new Date(dateStr))
}

export function formattedIsoDateTime(dateStr) {
  const isoFormat = format('yyyy-MM-dd HH:mm')
  return isoFormat(new Date(dateStr))
}
