// Final edited
<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-card
        min-width="300px"
        max-width="350"
        class="px-6 py-3"
        elevation="0"
        rounded="xl"
        dark
      >
        <v-card-title class="d-block pb-0">
          <h1 class="text-center" :class="checkTypeStyle">
            {{ checkTypeText }}
          </h1>
          <h5 class="text-overline font-weight-bold text-center mb-3">
            · · · access · · · vid · · ·
          </h5>
          <v-row class="pa-2 rounded-lg">
            <v-col cols="12">
              <div>
                <h1 class="text-h5 text-center">
                  {{ okOrDenied }}
                </h1>
                <div v-if="valid && !loading">
                  <h1 class="text-h4 text-center">
                    {{ welcome }}
                  </h1>
                  <h1 class="text-h5 text-center">{{ name || '' }}</h1>
                  <h1 class="text-h6 text-center" :class="checkTypeStyle">
                    {{ comment }}
                  </h1>
                </div>
              </div>
              <v-card class="text-center" raised elevation="2" rounded="xl">
                <v-card-title v-if="writing" class="justify-center text-h3">{{
                  code
                }}</v-card-title>
                <v-card-text class="text-h6">
                  <v-avatar v-if="!filledCode" color="grey" size="124">
                    <span class="white--text"></span
                    ><v-icon dark x-large class="custom-icon-large">
                      mdi-qrcode-scan
                    </v-icon>
                  </v-avatar>
                  <v-progress-circular
                    v-else-if="loading"
                    :rotate="360"
                    :size="100"
                    :width="20"
                    :value="waitingResponse"
                    color="grey"
                  >
                  </v-progress-circular>
                  <v-avatar
                    v-else-if="validCode"
                    color="teal darken-1"
                    size="124"
                  >
                    <span class="white--text"></span
                    ><v-icon dark x-large class="custom-icon-large">
                      {{ checkIcon }}
                    </v-icon>
                  </v-avatar>
                  <v-avatar v-else color="red accent-3" size="124">
                    <span class="white--text"></span
                    ><v-icon dark x-large class="custom-icon-large">
                      mdi-cancel
                    </v-icon>
                  </v-avatar>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              prepend-inner-icon="mdi-key-plus"
              label="Digita el código"
              type="number"
              required
              autocomplete="off"
              pattern="[0-9]*"
              min="1"
              max="999999"
              :maxlength="codeLength"
              v-model="code"
              clearable
              @click:clear="clearMessage"
              height="70"
              :rules="rules.code"
              :hint="hint"
              loading
              outlined
              class="custom-input mb-12 text-center"
              :counter="codeLength"
              :color="color"
              hide-details="auto"
              shaped
              ><template v-slot:progress>
                <v-progress-linear
                  :value="progress"
                  :color="color"
                  absolute
                  height="7"
                ></v-progress-linear> </template
            ></v-text-field>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  CODE_LENGTH,
  isEarlierPassException,
  isExpectedPass,
  timeToResetPassCode
} from '@/cfg'
import { sendCheck } from '@/store/modules/passApi/fbCheckApi'
import {
  qualityOfValidation,
  typeOfValidation
} from '@/store/modules/passApi/checkPolicies'
export default {
  data: () => ({
    responseInterval: {},
    waitingResponse: 0,
    checkType: '',
    validationQuality: {},
    welcome: '',
    comment: '',
    fromTheBegining: true,
    valid: false,
    validCode: false,
    name: '',
    messages: '',
    code: '',
    codeSent: '',
    hint: '',
    loading: false,
    rules: {
      code: [
        v => !!v || 'Es requerido para comprobar su pase de entrada',
        v => (v && v.length == CODE_LENGTH) || 'Se esperan 6 dígitos'
      ]
    }
  }),
  computed: {
    ...mapGetters('user', ['context']),
    isCheckin() {
      return this.checkType === 'checkin'
    },
    isCheckout() {
      return this.checkType === 'checkout'
    },
    qualifiedStatus() {
      const quality = this.validationQuality
      const isExpected = isExpectedPass(quality)
      const isEarlier = isEarlierPassException(quality)
      if (this.isCheckin && isExpected) {
        return 'checkin_expected'
      } else if (this.isCheckin && isEarlier) {
        return 'checkin_earlier'
      } else if (this.isCheckin) {
        return 'checkin_late'
      } else if (this.isCheckout && isExpectedPass) {
        return 'checkout_expected'
      } else if (this.isCheckout && isEarlier) {
        return 'checkout_earlier'
      } else if (this.isCheckout) {
        return 'checkout_late'
      } else {
        return 'unknown'
      }
    },
    checkIcon() {
      if (this.qualifiedStatus === 'checkin_expected') {
        return 'mdi-check-decagram'
      } else if (this.qualifiedStatus === 'checkin_earlier') {
        return 'mdi-check-circle'
      } else if (this.qualifiedStatus === 'checkin_late') {
        return 'mdi-sleep'
      } else if (this.qualifiedStatus === 'checkout_expected') {
        return 'mdi-hand-wave'
      } else if (this.qualifiedStatus === 'checkout_earlier') {
        return 'mdi-exit-run'
      } else if (this.qualifiedStatus === 'checkout_late') {
        return 'mdi-power-sleep'
      } else {
        return 'mdi-help'
      }
    },
    checkTypeText() {
      if (!this.valid || this.checkType === '') {
        return 'Registro'
      } else if (this.checkType === 'checkin') {
        return 'Entrada'
      } else if (this.checkType === 'checkout') {
        return 'Salida'
      } else {
        return ''
      }
    },
    checkTypeStyle() {
      let textFont = ''
      if (this.qualifiedStatus === 'checkin_expected') {
        textFont = 'text-h5 font-weight-light'
      } else if (this.qualifiedStatus === 'checkin_earlier') {
        textFont = 'text-h4 font-weight-light'
      } else if (this.qualifiedStatus === 'checkin_late') {
        textFont = 'text-h3 font-weight-light'
      } else if (this.qualifiedStatus === 'checkout_expected') {
        textFont = 'text-h5 font-weight-light'
      } else if (this.qualifiedStatus === 'checkout_earlier') {
        textFont = 'text-h4 font-weight-light'
      } else if (this.qualifiedStatus === 'checkout_late') {
        textFont = 'text-h3 font-weight-light'
      } else {
        textFont = ''
      }

      return `${textFont} ${this.checkTypeColor}`
    },
    checkTypeColor() {
      // const quality = this.validationQuality
      // if (!this.filledCode) {
      //   return 'identity--text'
      // } else if (this.validCode && isExpectedPass(quality)) {
      //   return this.checkType === 'checkin'
      //     ? 'teal--text text--darken-1'
      //     : 'light-blue--text text--darken-1'
      // } else if (this.validCode && isExceptionPass(quality)) {
      //   return isEarlierPassException(quality)
      //     ? 'yellow--text text--lighten-1'
      //     : 'deep-orange--text text--accent-4'
      // } else {
      //   return 'red--text text--accent-3'
      // }

      let colorText = ''
      if (!this.filledCode) {
        colorText = 'identity--text'
      } else if (this.qualifiedStatus === 'checkin_expected') {
        colorText = 'teal--text text--darken-1'
      } else if (this.qualifiedStatus === 'checkin_earlier') {
        colorText = 'yellow--text text--lighten-1'
      } else if (this.qualifiedStatus === 'checkin_late') {
        colorText = 'deep-orange--text text--accent-4'
      } else if (this.qualifiedStatus === 'checkout_expected') {
        colorText = 'light-blue--text text--darken-1'
      } else if (this.qualifiedStatus === 'checkout_earlier') {
        colorText = 'yellow--text text--lighten-1'
      } else if (this.qualifiedStatus === 'checkout_late') {
        colorText = 'deep-orange--text text--accent-4'
      } else {
        colorText = 'red--text text--accent-3'
      }
      return colorText
    },
    okOrDenied() {
      if (this.fromTheBegining) return ''
      return this.valid ? 'Válido' : 'Denegado'
    },
    writing() {
      return this.code && this.code.length > 0
    },
    filledCode() {
      return !!this.code && this.code.length === CODE_LENGTH
    },
    codeLength() {
      return CODE_LENGTH
    },
    currentLength() {
      return this.code ? this.code.length : 0
    },
    progress() {
      return Math.min(100, (this.currentLength * 100) / CODE_LENGTH)
    },
    color() {
      return !this.filledCode
        ? ['error', 'warning', 'white'][Math.floor(this.progress / 40)]
        : this.validCode
        ? 'teal'
        : 'white'
    }
  },
  watch: {
    valid(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.check().catch(error => console.log('Can not validate it', error))
      }
    }
  },
  methods: {
    ...mapActions('user', ['login']),
    clearMessage() {
      this.reset()
    },
    validate() {
      this.$refs.form.validate()
    },
    reset() {
      this.clearResponseInterval()
      this.fromTheBegining = true
      this.$refs.form.reset()
      this.validCode = false
      this.checkType = ''
    },
    resetValidation() {
      this.fromTheBegining = true
      this.$refs.form.resetValidation()
    },
    clearResponseInterval() {
      if (Object.keys(this.responseInterval).length === 0) {
        clearInterval(this.responseInterval)
        this.responseInterval = {}
      }
    },
    async check() {
      this.loading = true
      if (this.code && this.code.length === CODE_LENGTH) {
        this.codeSent = this.code
        this.startWaitingResponse()
        try {
          const validation = await this.callValidation()
          this.loading = false
          this.endWaitingResponse()
          this.validCode = validation.successful
          this.checkType = typeOfValidation(validation)
          this.validationQuality = qualityOfValidation(validation)
          this.name = validation.name
          this.welcome = validation.welcome
          this.comment = validation.comment
          setTimeout(() => {
            if (this.filledCode) {
              this.reset()
            }
          }, timeToResetPassCode(this.validCode))
        } catch (error) {
          console.log('Can not validate', error)
          this.loading = false
        }
      }
    },
    async callValidation() {
      return await sendCheck(this.code, this.context, this.$store)
    },
    startWaitingResponse() {
      this.responseInterval = setInterval(() => {
        if (this.waitingResponse === 100) {
          return (this.waitingResponse = 0)
        }
        this.waitingResponse += 25
      }, 1000)
    },
    endWaitingResponse() {
      this.waitingResponse = 100
      this.clearResponseInterval()
    }
  },
  created() {
    this.code = this.$route.params.id
  }
}
</script>

<style>
.custom-icon-large {
  font-size: 100px !important;
}
.custom-input {
  font-size: 2.6em;
  max-height: 56px;
  padding: 16px;
}
.custom-input input {
  letter-spacing: 0.6rem;
  width: 180px;
  max-height: 50px;
}
</style>
