import { format } from 'date-fns/fp'
import { subDays } from 'date-fns'
export function currentPeriodAsId() {
  return dateAsId(new Date())
}

function dateAsId(date) {
  const formatted = format('yyyyMM')
  const prefix = formatted(date)
  return `${prefix}${weekRange(date)}`
}

export function previousPeriodAsId() {
  const previous = subDays(new Date(), 1)
  const formatted = format('yyyyMM')
  const prefix = formatted(previous)
  return `${prefix}${weekRange(previous)}`
}

function weekRange(date) {
  const formatted = format('dd')
  const dd = formatted(date)
  const day = Number(dd)
  let w
  if (day <= 7) w = 1
  else if (day <= 14) w = 2
  else if (day <= 21) w = 3
  else w = 4
  return w
}
